'use strict'

const Accessibility = {
    init: function () {
        const html = document.querySelector('html');
        const buttonPlus = document.querySelector('.js-plus');
        const buttonMinus = document.querySelector('.js-minus');
        if (buttonPlus) {
            buttonPlus.addEventListener('click', () => {
                const htmlFZ = parseFloat(window.getComputedStyle(html).fontSize);
                if (htmlFZ < 13) {
                    buttonMinus.removeAttribute('disabled');
                    buttonPlus.removeAttribute('disabled');
                    html.style.fontSize = (htmlFZ + 1) + 'px';
                } else {
                    buttonPlus.setAttribute('disabled', 'disabled');
                }
                const iconMinus = buttonMinus.querySelector('i');
                const iconPlus = buttonPlus.querySelector('i');
                if (html.style.fontSize === "10px") {
                    if (iconPlus.classList.contains('active')) {
                        iconPlus.classList.remove('active');
                    }
                    if (iconMinus.classList.contains('active')) {
                        iconMinus.classList.remove('active');
                    }
                } else {
                    if (!iconPlus.classList.contains('active')) {
                        iconPlus.classList.add('active');
                    }
                    if (iconMinus.classList.contains('active')) {
                        iconMinus.classList.remove('active');
                    }
                }
            });
        }
        if (buttonMinus) {
            buttonMinus.addEventListener('click', () => {
                const htmlFZ = parseFloat(window.getComputedStyle(html).fontSize);
                if (htmlFZ > 6) {
                    buttonPlus.removeAttribute('disabled');
                    buttonMinus.removeAttribute('disabled');
                    html.style.fontSize = (htmlFZ - 1) + 'px';
                } else {
                    buttonMinus.setAttribute('disabled', 'disabled');
                }
                const iconMinus = buttonMinus.querySelector('i');
                const iconPlus = buttonPlus.querySelector('i');
                if (html.style.fontSize === "10px") {
                    if (iconMinus.classList.contains('active')) {
                        iconMinus.classList.remove('active');
                    }
                    if (iconPlus.classList.contains('active')) {
                        iconPlus.classList.remove('active');
                    }
                } else {
                    if (!iconMinus.classList.contains('active')) {
                        iconMinus.classList.add('active');
                    }
                    if (iconPlus.classList.contains('active')) {
                        iconPlus.classList.remove('active');
                    }
                }
            });
        }
    }
}

export default Accessibility;