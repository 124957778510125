'use strict'

import {gsap} from "gsap";

const Intro = {
    init: function () {
        const container = document.querySelector('.page__intro');
        if (container) {
            Intro.initLoader(container);
        }
    },
    initLoader: function (container) {
        let delayLoaderOut = 4;
        let patternRightPos = 0;
        if (window.matchMedia('(max-width:767px)').matches) {
            patternRightPos = -85;
        }

        const pattern = document.querySelector(".page__intro-signs");
        const text = document.querySelector(".page__intro-container");
        const closeButton = document.querySelector(".page__intro-close");
        const closeArea = document.querySelector(".page__intro");

        const tlLoaderIn = gsap.timeline({
            id: "tlLoaderIn",
            defaults: {
                ease: "power2.out"
            },
            onComplete: () => {
                setTimeout(() => {
                    document.querySelector(".app").classList.remove("is-loading");
                }, delayLoaderOut * 1000);
            }
        });

        tlLoaderIn
            .set(text, {autoAlpha: 1})
            .to(pattern, {
                duration: 1.4,
                webkitClipPath: "inset(0% 0% 0% 0%)",
                clipPath: "inset(0% 0% 0% 0%)",
                right: patternRightPos
            })
            .addLabel("revealPattern")
            .from(text, {
                duration: 2,
                opacity: 0,
                xPercent: -60,
                ease: "power1.inOut",
            }, "revealPattern-=2.2")
            .from(closeButton, {
                duration: 1,
                autoAlpha: 0,
                ease: "power1.inOut",
            }, "revealPattern-=.5")

        // const tlLoaderOut = gsap.timeline({
        //     id: "tlLoaderOut",
        //     defaults: {
        //         duration: 1.2,
        //         ease: "power2.inOut"
        //     },
        //     delay: delayLoaderOut
        // });

        // tlLoaderOut
        //     .to(container, {xPercent: 100}, 0.2)
        //     .from(".pageCtn", {x: -200}, 0.2)

        const tlLoader = gsap.timeline();
        // tlLoader.add(tlLoaderIn).add(tlLoaderOut);

        closeArea.addEventListener('click', () => {
            tlLoader.pause();
            document.querySelector(".app").classList.remove("is-loading");
            const tlLoaderOut2 = gsap.timeline({
                id: "tlLoaderOut",
                defaults: {
                    duration: 1.2,
                    ease: "power2.inOut"
                },
            });

            tlLoaderOut2
                .to(container, {xPercent: 100}, 0.2)
                .to(".pageCtn", {x: 0}, 0.2)
        });
    }
}

export default Intro;