'use strict';

const axios = require('axios');

const Form = {
    el: null,
    init: function () {
        Form.el = document.querySelector('.c-form');
        if (Form.el) {
            Form.manageForm(Form.el);
        }
    },
    manageForm: function (form) {
        if (form) {
            let inputs = form.querySelectorAll('.form-group input, .form-group textarea, .form-group select');

            for (let i = 0; i < inputs.length; i++) {
                const elementError = inputs[i].closest('.form-row').querySelector('.form-error');

                let eventList = ["change", "keyup", "paste", "input", "propertychange"];
                for (event of eventList) {
                    inputs[i].addEventListener(event, function () {

                        if (form.querySelector('.form-error.global--').classList.contains('active')) {
                            form.querySelector('.form-error.global--').classList.remove('active');
                        }

                        // Each time the user types something, we check if the
                        // form fields are valid.
                        if (!inputs[i].validity.valid) {
                            // If there is still an error, show the correct error
                            Form.showError(form, inputs[i], elementError);
                            // console.log(inputs[i].validity);
                        } else {
                            // In case there is an error message visible, if the field
                            // is valid, we remove the error message.
                            elementError.textContent = ''; // Reset the content of the message
                            inputs[i].closest('.form-group').classList.remove('error'); // Reset the visual state of the message
                        }
                    });
                }
            }


            form.querySelector('.js-submit').addEventListener('click', function (e) {
                let validArr = [];
                for (let i = 0; i < inputs.length; i++) {
                    const elementError = inputs[i].closest('.form-row').querySelector('.form-error');
                    if (!inputs[i].validity.valid) {
                        // If it isn't, we display an appropriate error message
                        Form.showError(form, inputs[i], elementError);
                        validArr.push(false);
                    } else {
                        validArr.push(true);
                        elementError.textContent = ''; // Reset the content of the message
                        inputs[i].closest('.form-group').classList.remove('error'); // Reset the visual state of the message
                    }
                }

                if (validArr.includes(false)) {
                    // Then we prevent the form from being sent by canceling the event
                    form.querySelector('.global--').classList.add('active');
                    // e.preventDefault();
                } else {
                    form.querySelector('.global--').classList.remove('active');


                    let url = api.newsletter_url;
                    if (location.hostname === "localhost" || location.hostname === "127.0.0.1") {
                        url = "http://ax-landing.local/wp-json/df-form/v1/save/newsletter";
                    }
                    if (url) {

                        form.querySelector('.js-submit').classList.add('loading');

                        if (form.querySelector('.global-- span')) {
                            form.querySelector('.global-- span').textContent = '';
                        }

                        let formData = new FormData(form);

                        axios({
                            method: "post",
                            url: url,
                            data: formData,
                            headers: {"Content-Type": "multipart/form-data"},
                        }).then(function (response) {
                                //console.log('then response', response);

                                //handle success
                                form.querySelector('.js-submit').classList.remove('loading');
                                if (response.data.code === "all_good") {
                                    form.querySelector('.global--').classList.add('active');
                                    form.querySelector('.global-- span').innerHTML = response.data.data.msg;
                                    form.querySelector('.form-group.submit').classList.add('sent');
                                    form.reset();
                                } else {
                                    form.querySelector('.global--').classList.add('active');

                                    if (response.data.data.errors) {
                                        let arr = response.data.data.errors;
                                        if (arr) {
                                            if (!Array.isArray(response.data.data.errors)) {
                                                arr = Object.entries(response.data.data.errors).map((e) => ({[e[0]]: e[1]}));
                                            }
                                            arr.forEach(item => {
                                                Object.keys(item).forEach((key) => {
                                                    if (key !== 'global') {
                                                        const formRow = form.querySelector('[name="' + key + '"]').closest('.form-row');
                                                        if (formRow) {
                                                            formRow.querySelector('.form-group').classList.add('error');
                                                            formRow.querySelector('.form-error').innerHTML = item[key];
                                                        }
                                                    }
                                                });
                                            });

                                            //console.log('errors', arr);
                                        }
                                    }
                                    form.querySelector('.global-- span').innerHTML = response.data.data.errors.global ? response.data.data.errors.global : response.data.message;
                                }
                            }
                        ).catch(function (response) {
                            //handle error
                            console.log('err', response);

                            form.querySelector('.js-submit').classList.remove('loading');
                            if (form.querySelector('.global--')) {
                                form.querySelector('.global--').classList.add('active');
                                form.querySelector('.global-- span').innerHTML = response.message;
                            }
                            form.reset();
                        });
                    }
                }

            });
        }
    },
    showError: function (form, element, elementError) {
        if (element.validity.valueMissing) {
            // If the field is empty,
            // display the following error message.
            elementError.textContent = form.querySelector('.error-message-required').textContent;
        } else if (element.validity.typeMismatch) {
            // If the field doesn't contain an email address,
            // display the following error message.
            if (element.dataset.alphabet) {
                elementError.textContent = form.querySelector('.error-message-invalid-alphabet').textContent;
            } else if (element.dataset.email) {
                elementError.textContent = form.querySelector('.error-message-invalid-email').textContent;
            } else if (element.dataset.phone) {
                elementError.textContent = form.querySelector('.error-message-invalid-phone').textContent;
            } else {
                elementError.textContent = form.querySelector('.error-message-invalid').textContent;
            }
        } else if (element.validity.tooLong) {
            // If the data is too long,
            // display the following error message.
            elementError.textContent = form.querySelector('.error-message-toolong').textContent.replace('[MAXLENGTH]', element.dataset.maxlength);
        } else if (element.validity.tooShort) {
            // If the data is too short,
            // display the following error message.
            elementError.textContemnt = form.querySelector('.error-message-tooshort').textContent;
        } else if (element.validity.patternMismatch) {
            elementError.textContent = form.querySelector('.error-message-invalid').textContent;
        } else if (element.dataset.compare) {
            elementError.textContent = form.querySelector('.error-message-compare').textContent;
        }

        // Set the styling appropriately
        element.closest('.form-group').classList.add('error');
    },
};

export default Form;