'use strict'
// core version + modules:
import Swiper from 'swiper';
import {Pagination} from 'swiper/modules';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

const Slider = {
    init: function () {
        const sliders = document.querySelectorAll('.js-slider');
        if (sliders) {
            sliders.forEach(slider => {
                const swiper = new Swiper(slider, {
                    modules: [Pagination],
                    slidesPerView: 1.25,
                    spaceBetween: 24,
                    grabCursor: true,
                    speed: 500,
                    pagination: {
                        el: slider.querySelector('.js-nav'),
                        clickable: true,
                    },
                });
            })
        }
    }
}

export default Slider;