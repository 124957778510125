'use strict'

const Sound = {
    init: function () {
        const button = document.querySelector('.js-sound');
        if (button) {
            const icon = button.getElementsByTagName('i')[0];
            const ambient = document.getElementById('ambient');
            document.addEventListener('click', Sound.playAmbientSoundOnce, { once: true });
            if (ambient && icon) {
                
                button.addEventListener('click', (e) => {
                    e.preventDefault();
                    if (icon.classList.contains('icon-sound-on')) {
                        // Stop
                        icon.classList.add('icon-sound-off');
                        icon.classList.remove('icon-sound-on');
                        ambient.pause();
                        document.getElementById('our-vision-sound')?.pause();
                        document.getElementById('our-offer-sound')?.pause();
                        document.getElementById('our-mission-sound')?.pause();
                    } else if (icon.classList.contains('icon-sound-off')) {
                        // Play
                        icon.classList.add('icon-sound-on');
                        icon.classList.remove('icon-sound-off');
                        ambient.volume = .4;
                        ambient.play();
                    }
                });


                const closePackshotsButtons = document.querySelectorAll('.js-close');
                if (closePackshotsButtons && closePackshotsButtons.length > 0) {
                    closePackshotsButtons.forEach(button => {
                        button.addEventListener('click', () => Sound.manageCloseSound(icon));
                    })
                }
                const closeIntroButton = document.querySelector('.page__intro-close');
                if (closeIntroButton) {
                    closeIntroButton.addEventListener('click', () => Sound.manageCloseSound(icon));
                }
            }
        }

    },
    playAmbientSoundOnce: function () {
        const ambient = document.getElementById('ambient');
        if (ambient) {
            ambient.volume = .4;
            ambient.play().catch(error => {
                console.error('Failed to play ambient sound:', error);
            });
        }
    },
    manageCloseSound: function (icon) {
        if (icon.classList.contains('icon-sound-on')) {
            document.getElementById('our-vision-sound').play();
        }
    }
}

export default Sound;