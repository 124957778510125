'use strict'

import SimpleScrollbar from '../lib/simple-scrollbar-hdf'
import 'simple-scrollbar/simple-scrollbar.css';

const Scrollbar = {
    init: function (el) {
        if (el) {
            SimpleScrollbar.initEl(el);
        }
    },
}

export default Scrollbar;