import Accessibility from "./components/Accessibility"
import Slider from "./components/Slider"
import Sound from "./components/Sound";
import Intro from "./components/Intro";
import Packshots from "./components/Packshots";
import Form from "./components/Form";
import Languages from "./components/Languages";

const main = {
    init: function () {
        Accessibility.init();
        Slider.init();
        Sound.init();
        Intro.init();
        Packshots.init();
        Form.init();
        Languages.init();
    }
}

export default main