'use strict'

import {gsap} from "gsap";
import Scrollbar from "./Scrollbar";

const Packshots = {
    img: null,
    content: null,
    buttonsOnImg: [],
    init: function () {
        Packshots.img = document.querySelector('.page__content-packshots-img');
        Packshots.content = document.querySelector('.page__content-packshots-content');

        const listItems = Packshots.content.querySelectorAll(':scope >li');
        listItems.forEach(li => {
            gsap.set(li, {xPercent: 100});
        });

        Packshots.buttonsOnImg = Array.from(Packshots.img.querySelectorAll('button'));

        if (Packshots.buttonsOnImg.length > 0) {
            Packshots.buttonsOnImg.forEach(button => {
                button.addEventListener('click', () => {
                    Packshots.manageButtonsOnImg(button);
                    Packshots.manageContent(button);
                    Packshots.manageSound(button);
                });
            });
        }

        const buttonLegalMentions = document.querySelector('.page__footer-links-lm');
        if (buttonLegalMentions) {
            buttonLegalMentions.addEventListener('click', () => {
                Packshots.manageButtonsOnImg(null);
                Packshots.manageContent(buttonLegalMentions);
                Packshots.manageSound(buttonLegalMentions);
                window.scrollTo(0, 0);
            });
        }
    },
    manageButtonsOnImg: function (button) {
        // Remove any existing active/inactive class from all buttons
        Packshots.buttonsOnImg.forEach(btn => {
            btn.classList.remove('active');
            btn.classList.remove('inactive');
        });

        if (button) {
            // Add active class to the clicked button
            button.classList.add('active');

            // Select all other buttons except the clicked one
            const otherButtons = Packshots.buttonsOnImg.filter(btn => btn !== button);

            // Do something with otherButtons
            // For example, you can add a class or perform any other action
            otherButtons.forEach(btn => {
                // Do something with each other button
                btn.classList.add('inactive');
            });
        }
    },
    manageContent: function (button) {
        const target = button.dataset.id;
        const oldActive = Packshots.content.querySelector('.active');
        const newActive = Packshots.content.querySelector('#' + target);
        newActive.classList.add('active');
        gsap.to(newActive, {
                xPercent: 0,
                duration: .7,
                ease: "power2.inOut",
                force3D: true
            }
        );

        Scrollbar.init(newActive.querySelector('.js-scrollbar'));

        // Remove all classes
        Packshots.img.classList.remove('red', 'yellow', 'green');

        // Add specific class based on target
        if (target === "our-vision") {
            Packshots.img.classList.add('green');
        } else if (target === "our-mission") {
            Packshots.img.classList.add('red');
        } else if (target === "our-offer") {
            Packshots.img.classList.add('yellow');
        }

        if (oldActive) {
            if (oldActive !== newActive) {
                oldActive.classList.remove('active');
                gsap.to(oldActive, {
                        xPercent: 100,
                        duration: .7,
                        ease: "power2.inOut",
                    }
                );
            }
        } else {
            gsap.set(Packshots.content, {autoAlpha: 1});
            if (window.matchMedia('(min-width:768px)').matches) {
                gsap.to(Packshots.img, {
                    x: -230,
                    duration: .7,
                    ease: "power2.inOut"
                });
            }
        }

        const buttonsContentNav = newActive.querySelector('.page__content-packshots-content-nav')?.querySelectorAll('button');
        if (buttonsContentNav) {
            buttonsContentNav.forEach(button => {
                button.addEventListener('click', () => {
                    Packshots.manageButtonsOnImg(Packshots.img.querySelector("[data-id=" + button.dataset.id + "]"));
                    Packshots.manageContent(button);
                    Packshots.manageSound(button);
                });
            })
        }

        const closeButton = newActive.querySelector('.js-close');
        if (closeButton) {
            closeButton.addEventListener('click', () => {
                Packshots.manageButtonsOnImg(null);
                // Remove all classes
                Packshots.img.classList.remove('red', 'yellow', 'green');
                if (window.matchMedia('(min-width:768px)').matches) {
                    gsap.to(Packshots.img, {
                        x: 0,
                        duration: .65,
                        ease: "power1.out",
                    });
                }
                gsap.fromTo(newActive, {xPercent: 0}, {
                        xPercent: 100,
                        duration: .7,
                        ease: "power1.out",
                        onComplete: () => {
                            newActive.classList.remove('active');
                            gsap.set(Packshots.content, {autoAlpha: 0});
                        }
                    }
                );
            });
        }
    },
    manageSound: function (button) {
        const buttonSound = document.querySelector('.js-sound');
        if (buttonSound) {
            const icon = buttonSound.getElementsByTagName('i')[0];
            if (icon.classList.contains('icon-sound-on')) {
                let target = button.dataset.id + '-sound';
                if (target === "legal-mentions-sound") {
                    target = 'our-mission-sound';
                }
                let oldActive = Packshots.content.querySelector('.active').getAttribute('id') + '-sound';
                if (oldActive === "legal-mentions") {
                    oldActive = 'our-mission-sound';
                }

                document.getElementById(target).play();
                if (target !== oldActive) {
                    if (document.getElementById(oldActive)) {
                        document.getElementById(oldActive).pause();
                    }
                }
            }
        }
    }
}

export default Packshots;