'use strict'

const Languages = {
    el: null,
    init: function () {
        Languages.el = document.querySelector('.page__header-languages');
        if (Languages.el) {
            // Short label on small screens
            if (window.matchMedia('(max-width:767px)').matches) {
                const activeLang = Languages.el.querySelector('.active').dataset.short;
                const spanActive = Languages.el.querySelector('.inner');
                spanActive.innerHTML = activeLang;
                const listItems = Languages.el.querySelectorAll('li a');
                if (listItems && listItems.length > 0) {
                    listItems.forEach(link => {
                        link.innerHTML = link.parentElement.dataset.short;
                    });
                }
            }
            // Toggle active class on click visible span
            const active = Languages.el.querySelector(':scope >span');
            active.addEventListener('click', () => {
                Languages.el.classList.toggle('active');
            });
            // Close list on click outside
            document.addEventListener('click', event => {
                if (!Languages.el.contains(event.target)) {
                    Languages.el.classList.remove('active');
                }
            });
        }

    }
}

export default Languages;